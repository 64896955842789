<template>
  <div>
    <b-modal
      ref="setup-modal"
      id="setup-modal"
      title="ใบงาน"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <!-- START ROW Log -->
        <b-row class="mt-2">
          <b-col md="12 ">
            <b-table
              class="shadow"
              style="font-size: 12px"
              small
              striped
              bordered
              hover
              :items="tickets"
              :fields="fields"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(to)="row">
                <b-button
                  class="btn btn-success btn-sm"
                  @click="selectTicket(row.item, false)"
                  >เลือก</b-button
                >            
              </template>

              <template #cell(back)="row">               
                <b-button
                  class="btn btn-secondary btn-sm"
                  @click="selectTicket(row.item, true)"
                  >เลือก</b-button
                >
              </template>

            </b-table>
            <!-- <center>
              <b-button variant="primary" size="sm" @click="selectTicket()"
                >กลับบ้าน</b-button
              >
            </center> -->
          </b-col>
        </b-row>
        <!-- END ROW Log -->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BTable,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  props: ["tickets", "isLoading"],
  components: { BCardText, BRow, BCol, vSelect, BTable, BButton, BSpinner },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      fields: [
        {
          key: "no",
          label: "#",
          sortable: true,
          thStyle: {
            width: "5%",
            textAlign: "center",
            color: "white",
          },
        },

        {
          key: "ticketNo",
          label: "เลขที่ใบงาน",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "15%" },
        },
        {
          key: "branchCode",
          label: "รหัสสาขา",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "12%" },
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "problemDetail",
          label: "รายละเอียด",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },

        {
          key: "to",
          label: "ขาไป",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "8%" },
        },
        {
          key: "back",
          label: "ขากลับ",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "8%" },
        },
        
      ],
    };
  },
  methods: {
    selectTicket(item, isBack=false) {
      if (!item) {
        item = {
          branchName: "xxx",
          branchCode: "xxx",     
          ticketNo: "xxx",    
          problemSolving: "กลับบ้าน",
          isBack
        };
      }
      this.$emit("selectedTicket", {...item, isBack});
      this.$refs["setup-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
