export const GET_COOKIE = (cname, isParse = true) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  let cokkie = null;
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      cokkie = c.substring(name.length, c.length);
    }
  }

  if (isParse) {
    return JSON.parse(cokkie) || null;
  }

  return cokkie || null;
};

export const GET_LOCALSTORAGE = name => {
  return JSON.parse(localStorage.getItem(name));
};

export const REMOVE_COOKIE = cname => {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    if (name.includes(cname)) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
};

export const GET_CURRENT_DATE = (_date)=> {
  let d = new Date();
  if(_date){
    d = _date
  }
  d.setHours(d.getHours()+7);
  const dISO = d.toISOString();
  const re1 = dISO.replace("T", " ");
  const re2 = re1.replace("Z", "");
  const date = re2.split(' ')[0];
  const yearMonth = date.substr(0, 7);

  let hours = d.getHours() >= 10?d.getHours():`0${d.getHours()}`;
  let minutes = d.getMinutes() >= 10?d.getMinutes():`0${d.getMinutes()}`;
  let seconds = d.getSeconds() >= 10?d.getSeconds():`0${d.getSeconds()}`;
  const time_now = `${hours}:${minutes}:${seconds}`;

  return {
    timestamp: re2.split('.')[0],
    date: date,
    time_now: time_now,
    yearMonth: yearMonth
  };
}
