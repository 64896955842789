<template>
  <div >
    <!-- START SECTION เพิ่มรายการ--->
    <b-card>
      <!-- START ROW วันทืี่ -->
      <b-row>
        <b-col md="3" class="" id="targetElement">
          <label>วันที่ <span class="text-danger">*</span></label>
          <b-form-datepicker
            id="incentive-detail"
            locale="th-TH"
            v-model="incentiveDetailData.date"
            @input="getTicketByAssigneeId(1)"
            show-decade-nav
            class="mb-1"
            size="sm"
          />
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end" >
          <h3><b>แบบฟอร์มกรอกระยะทาง</b></h3>
        </b-col>
      </b-row>
      <!-- END ROW วันทืี่ -->

      <!-- START ROW เลขที่ใบงาน -->
      <b-row class="mt-1">
        <b-col md="3" class="">
          <label>เลขที่ใบงาน <span class="text-danger">*</span></label>
          <b-input-group>
            <b-input-group-prepend
              is-text
              v-b-modal.setup-modal
              @click="getTicketByAssigneeId(0)"
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="เลขที่ใบงาน"
              size="sm"
              readonly
              v-model="ticketSelected.ticketNo"
            />
          </b-input-group>
        </b-col>
        <b-col md="2" class="">
          <label>รหัสสาขา</label>
          <b-form-input
            placeholder="รหัสสาขา"
            size="sm"
            readonly
            v-model="ticketSelected.branchCode"
          />
        </b-col>
        <b-col>
          <label>ชื่อสาขา</label>
          <b-form-input
            placeholder="ชื่อสาขา"
            size="sm"
            readonly
            v-model="ticketSelected.branchName"
          />
        </b-col>
      </b-row>
      <!-- END ROW เลขที่ใบงาน -->

      <!-- START ROW อ้างอิงใบงาน -->
      <b-row class="mt-1">
        <b-col md="3" class="">
          <label>อ้างอิงใบงาน (กลับบ้าน) <span class="text-danger">*</span></label>
          <b-form-input
            placeholder="อ้างอิงใบงาน"
            size="sm"
            v-model.trim="refTicketNoInput"
          />
        </b-col>

        <b-col>
          <label>รายละเอียด</label>
          <b-form-input
            placeholder="รายละเอียด"
            size="sm"
            readonly
            v-model="ticketSelected.problemSolving"
          />
        </b-col>
      </b-row>
      <!-- END ROW อ้างอิงใบงาน -->

      <!-- Start Border Devide-->
      <b-row class="my-3">
        <b-col>
          <div style="border: 1px dashed #ccc"></div>
        </b-col>
      </b-row>
      <!-- End Border Devide-->

      <!-- START ROW ขาไป -->
      <b-row class="">
        <b-col md="5" class="">
         
          <label>{{ showGotoOrBackHomeLabel  }} <span class="text-danger">*</span></label>
          <v-select
            id="travelDay"
            v-model="incentiveDetailData.locationForm"
            @input="autoRefTicketNoInput"
            label="title"
            :options="locationOptions"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>

        <b-col>
          <label>ถึง <span class="text-danger">*</span></label>
          <v-select
            id="travelDay"
            v-model="incentiveDetailData.locationTo"
            label="title"
            :options="locationOptionsTo"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <!-- END ROW ขาไป -->

      <!-- START ROW ไมล์เริ่มต้น -->
      <b-row class="mt-1">
        <b-col md="1" class="">
          <label>ไมล์เริ่มต้น</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.distanceFrom"
            @keyup="calKilo"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ไมล์สิ้นสุด</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.distanceTo"
            @keyup="calKilo"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าทางด่วน</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.expressPosition"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าเบี้ยเลี้ยง</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.allowance"
          />
        </b-col>
        <b-col md="1" class="">
          <label>incentive</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.incentive"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าโรงแรม</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.feeHotel"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าน้ำมัน/แก๊ส</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.feeOilGas"
          />
        </b-col>
        <b-col md="1" class="">
          <label>Rate</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.feeOilGasRate"
          />
        </b-col>
        <b-col md="1" class="">
          <label>อื่นๆ</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.freeOther"
          />
        </b-col>
        <b-col>
          <label>รายละเอียด</label>
          <b-form-input
            placeholder="รายละเอียด"
            size="sm"
            v-model="incentiveDetailData.ticketDescription"
          />
        </b-col>
      </b-row>
      <!-- END ROW ไมล์เริ่มต้น -->

      <!-- START ROW ขากลับ -->
      <b-row class="mt-2" v-if="isback &&ticketSelected.ticketNo!=='xxx'">
        <b-col md="5" class="">
          <label>ขากลับ <span class="text-danger">*</span></label>
          <v-select
            id="travelDay"
            v-model="incentiveDetailData.backHome.locationForm"
            label="title"
            :options="locationOptions"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>

        <b-col>
          <label>ถึง <span class="text-danger">*</span></label>
          <v-select
            id="travelDay"
            v-model="incentiveDetailData.backHome.locationTo"
            label="title"
            :options="locationOptionsTo"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <!-- END ROW ขากลับ -->

      <!-- START ROW ไมล์เริ่มต้น -->
      <b-row class="mt-1" v-if="isback&&ticketSelected.ticketNo!=='xxx'">
        <b-col md="1" class="">
          <label>ไมล์เริ่มต้น</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.distanceFrom"
            @keyup="calKilo"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ไมล์สิ้นสุด</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.distanceTo"
            @keyup="calKilo"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าทางด่วน</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.expressPosition"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าเบี้ยเลี้ยง</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.allowance"
          />
        </b-col>
        <b-col md="1" class="">
          <label>incentive</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.incentive"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าโรงแรม</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.feeHotel"
          />
        </b-col>
        <b-col md="1" class="">
          <label>ค่าน้ำมัน/แก๊ส</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.feeOilGas"
          />
        </b-col>
        <b-col md="1" class="">
          <label>Rate</label>
          <b-form-input
            placeholder="0"
            size="sm"
            v-model.number="incentiveDetailData.backHome.feeOilGasRate"
          />
        </b-col>
        <b-col md="1" class="">
          <label>อื่นๆ</label>
          <b-form-input
            placeholder="100"
            size="sm"
            v-model.number="incentiveDetailData.backHome.freeOther"
          />
        </b-col>
        <b-col>
          <label>รายละเอียด</label>
          <b-form-input
            placeholder="รายละเอียด"
            size="sm"
            v-model="incentiveDetailData.backHome.ticketDescription"
          />
        </b-col>
      </b-row>
      <!-- END ROW ไมล์เริ่มต้น -->

      <!-- START ROW ปุ่มเพิ่มรายการ -->
      <b-row class="mt-1">
        <b-col cols="2">           
        
          <b-form-checkbox v-model="isback" @click="!isback" v-if="checkShowBackHomeCheckbox">
            เพิ่มขากลับ
          </b-form-checkbox>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button           
            variant="danger"
            size="sm"
            class="mr-1"
            @click="clearForm"
          >
            
            <span> ล้างข้อมูล</span>
   
          </b-button>
          <b-button           
            variant="info"
            size="sm"
            class="mr-1"
            @click="calKilo"
            :disabled="isLoadingCalKilo"
          >
            
            <span>{{ isLoadingCalKilo ? 'กำลังคำนวณ...':'คำนวณค่าเดินทาง' }} </span>
   
          </b-button>

          <b-button
            v-if="accountUserPermission=='create' || accountUserPermission=='edit'"
            variant="primary"
            size="sm"
            :disabled="incentiveOtData.processNo!==0 || isLoading"
            @click="onSave"
          >
            
            <span v-if="mode=='create'"> <feather-icon icon="PlusCircleIcon" /> เพิ่มรายการ</span>
            <span v-else><feather-icon icon="EditIcon" /> แก้ไขรายการ</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- END ROW ปุ่มเพิ่มรายการ -->
    </b-card>
    <!-- END SECTION เพิ่มรายการ--->

    <!-- START SECTION FORM วันเดินทาง--->
    <b-row>
      <b-col cols="12">
        <b-table
          style="font-size: 12px"
          class="shadow"
          small
          striped
          bordered
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="incentiveDetailList"
          :fields="visibleFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <template #cell(ticketNo)="row">          
            <b-badge :variant="row.item.isBackHome==1 ? 'secondary' : 'success'">{{row.item.ticketNo}}</b-badge>
          </template>

          <template #cell(action)="row">
            <center>
            <b-button
              variant="outline-secondary"
              class="mr-1"
              size="sm"
              pill
              :id="row.item.id"
              @click="onEditIncentiveDetail(row.item)"
              :disabled="incentiveOtData.processNo!==0"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-secondary"
              />
            </b-button> 

            <b-button
              variant="danger"
              size="sm"
              pill
              @click="deleteIncentiveDetail(row.item.id, row.item.ticketNo)"
              :disabled="incentiveOtData.processNo!==0"
            >
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer text-white"
              />
            </b-button>
          </center>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="2">
        <b>ระยะทางทั้งหมด : {{ footerSummaryAmount.sumKilo }} กิโลเมตร</b><br />
        <b>รวมค่าเดินทาง : {{ footerSummaryAmount.sumCost }} บาท</b>
      </b-col>

      <b-col cols="10" class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          variant="secondary"
          class="my-0"
        />
      </b-col>
    </b-row>
    <!-- END SECTION FORM วันเดินทาง--->
    <chooseJobNoModal
      :tickets="ticketList"
      :isLoading="isLoadingTicket"
      @selectedTicket="onSelectedTicket"
    />
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import chooseJobNoModal from "./modal/chooseJobNoModal";
import Field from "../field/incentiveDetail";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BSpinner,
  BBadge
} from "bootstrap-vue";

//SERVICES
import IncentiveOTService from "@/services/incentiveOT";
import TicketService from "@/services/ticket";
import { GET_CURRENT_DATE } from "@/utils/";

//MODEL
import IncentiveDetailModel from "../model/incentiveDetail";

export default {
  props: ["incentiveOtData", "userLogin"],
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BTable,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BPagination,
    BSpinner,
    BBadge,
    vSelect,
    chooseJobNoModal,
  },
  data() {
    return {
      locationSelected: { title: "Office", value: "office" },
      locationOptions: [
        { title: "Office", value: "office" ,isBranch:false},
        { title: "ที่พัก CM", value: "ที่พัก CM" ,isBranch:false},
        { title: "โรงแรม", value: "โรงแรม" ,isBranch:false},
      ],
      locationOptionsTo: [
        { title: "Office", value: "office" ,isBranch:false},
        { title: "ที่พัก CM", value: "ที่พัก CM" ,isBranch:false},
        { title: "โรงแรม", value: "โรงแรม" ,isBranch:false},
      ],

      fields: Field,
      incentiveDetailList: [],
      incentiveDetailData: {},
      ticketList: [],
      ticketSelected: {},
      ticketRef: {},
      refTicketNoInput: "",
      perPage: 1000,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isback: false, //ขากลับ Checkbox
      isLoading: false,
      isLoadingTicket: false,
      mode:"create",
      isBackHome:0,  // เป็นขากลับหรือไม่ ใช้เมื่อแก้ไขข้อมูลรายการ
      isShowCheckboxBackHome:false,
      footerSummaryAmount:{},
      isLoadingCalKilo:false

    };
  },

  watch: {
    ticketSelected(){
      if(this.ticketSelected){
        this.locationOptionsTo = [
        { title: "Office", value: "office" ,isBranch:false},
        { title: "ที่พัก CM", value: "ที่พัก CM" ,isBranch:false},
        { title: "โรงแรม", value: "โรงแรม" ,isBranch:false},
      ]

    
          this.locationOptionsTo.push({title:this.ticketSelected.branchName, value: this.ticketSelected.branchName})
                 
      }
    },
    refTicketNoInput() {
      this.getTicketByTicketNo(this.refTicketNoInput);
      if(this.refTicketNoInput){
        this.isShowCheckboxBackHome = false
      }else{
        this.isShowCheckboxBackHome = true
      }
      
    },
    
  },
  computed :{
    accountUserPermission () {
      return this.$store.getters["user/getPermissionPage"]("AC001")
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("AC001") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    },
    checkShowBackHomeCheckbox(){   
      
      if(this.incentiveDetailData.isBackHome==1){
        return false
      }

      if(this.ticketSelected.ticketNo=='xxx'){
        return false
      }

      if(this.refTicketNoInput){
        return false
      }

      return true
    },

    showGotoOrBackHomeLabel(){
      if(this.incentiveDetailData.isBackHome==1 ){
        return "ขากลับ"
      }

      if(this.ticketSelected.ticketNo=='xxx'){
        return "ขากลับ"
      }

      if(this.refTicketNoInput){
        return "ขากลับ"
      }

      return "ขาไป"
    }
  },
  methods: {
    validateForm(){
      if (!this.incentiveDetailData.date) {
        alert("กรุณาเลือกวันที่ใบงาน");
        return true;
      }

      if(!this.ticketSelected.branchCode || !this.ticketSelected.branchName || !this.ticketSelected.branchName ){
        alert("กรุณาเลือกใบงาน")
        return true
      }

      if(this.ticketSelected.branchCode=='xxx' && this.refTicketNoInput==""){
            alert("กรุณากรอกเลขใบงานอ้างอิง")
            return true
      }

      if(!this.incentiveDetailData.locationForm){
        alert("กรุณาเลือกขาไป")
        return true
      }
       
      if(!this.incentiveDetailData.locationTo){
        alert("กรุณาเลือกปลายทาง (ถึง)")
        return true
      }  

      //IF GO HOME
      if(this.isback){
          // if(this.refTicketNoInput==""){
          //   alert("กรุณากรอกเลขใบงานอ้างอิง")
          //   return true
          // }
          if(!this.incentiveDetailData.backHome.locationForm){
          alert("กรุณาเลือกขาไป")
          return true
          }
        
          if(!this.incentiveDetailData.backHome.locationTo){
            alert("กรุณาเลือกปลายทาง (ถึง)")
            return true
          }  
      }
 
    },

    async calKilo(){
      const item = this.incentiveDetailData;
      
      //TO
      let obj = {
        ticketNo:item.isBackHome ? this.refTicketNoInput : item.ticketNo ,
        locationForm: item.locationForm.value || item.locationForm,
        locationTo:item.locationTo.value || item.locationTo,
        isBackHome:this.refTicketNoInput!=="" ? true :false,
        ditstanceResult:item.distanceTo - item.distanceFrom,
      }     
      this.isLoadingCalKilo = true
      const result = await IncentiveOTService.CalKilo(obj);
      this.incentiveDetailData.feeOilGas = result.data.data.result
      this.incentiveDetailData.feeOilGasRate = result.data.data.kiliCost || 0


      if(this.isback){
      //BACK
      const itemBack = this.incentiveDetailData.backHome;
      let objBack = {
        ticketNo:this.incentiveDetailData.ticketNo || this.refTicketNoInput ,
        locationForm: itemBack.locationForm.value || itemBack.locationForm,
        locationTo:itemBack.locationTo.value || itemBack.locationTo,
        isBackHome:this.isback!=="" ? true :false,
        ditstanceResult:itemBack.distanceTo - itemBack.distanceFrom,
      }     
      
      
      const resultBack = await IncentiveOTService.CalKilo(objBack);
      this.incentiveDetailData.backHome.feeOilGas = resultBack.data.data.result
      this.incentiveDetailData.backHome.feeOilGasRate = resultBack.data.data.kiliCost || 0      
      }
      this.isLoadingCalKilo = false
      
    },
    async getIncentiveDetail() {
      this.isLoading = true;
      const paramIncentiveId = this.$route.params.incentiveId;
      const result = await IncentiveOTService.GetIncentiveDetailList(
        paramIncentiveId
      );
      this.incentiveDetailList = result.data;
      this.computeSummaryAmountFooter(result.data)
      this.isLoading = false;
    },
    async getTicketByAssigneeId(withdrawStatus=0) {
      if (!this.incentiveDetailData.date) {
        alert("กรุณาเลือกวันที่ก่อนเลือกใบงาน");
        return false;
      }
      this.isLoadingTicket = true;
      const result = await TicketService.GetTicketByAssigneeId(
        this.userLogin.userId,
        this.incentiveDetailData.date,
        withdrawStatus
      );
      this.ticketList = result.data;
      this.isLoadingTicket = false;



      //GO TO
      if(withdrawStatus==0){
        this.locationOptions = [
          { title: "Office", value: "office" ,isBranch:false},
          { title: "ที่พัก CM", value: "ที่พัก CM" ,isBranch:false},
          { title: "โรงแรม", value: "โรงแรม" ,isBranch:false},
        ]

        for (let index = 0; index < this.incentiveDetailList.length; index++) {
        const item = this.incentiveDetailList[index];
        const find  = this.locationOptions.filter(f=>f.value == item.branchName)
        if(find.length==0){
          this.locationOptions.push({title:`${item.branchName}`, value:item.branchName})
        }        
        
      }       
        
        return
      }



      //MAKE HOLDER BACKHOME FOR CHECK WHEN USED ITEM
      let jobHolderUsed = {}
      for (let index = 0; index < this.incentiveDetailList.length; index++) {
        const item = this.incentiveDetailList[index];
        if(item.isBackHome){
          jobHolderUsed[item.ticketNo] = true
        }        
      }

      //BUILD LIST FOR BACKHOME   
      for (let index = 0; index < this.ticketList.length; index++) {
        const item = this.ticketList[index];
        const find  = this.locationOptions.filter(f=>f.ticketNo == item.ticketNo)
        if(find.length==0 && !jobHolderUsed[item.ticketNo]){
          this.locationOptions.push({title:`${item.ticketNo} - ${item.branchName}`, value:item.branchName, isBranch:true, data:item, ticketNo:item.ticketNo})
        }        
      }
      
    },
    async getTicketByTicketNo(ticketNo) {
      // let text="VST-230027"
      if (ticketNo.length >= 10) {
        ticketNo;
        const result = await TicketService.GetTicketByTicketNo(ticketNo);
        let item = result.data;
        this.ticketRef = item;
        this.ticketSelected = item
        this.incentiveDetailData.ticketDescription = "เดินทางกลับ"
        

        const foundedLocation = this.locationOptions.some(
          (s) => s.value == item.branchName
        );
        if (!foundedLocation) {
          this.locationOptions.push({
            title: item.branchName,
            value: item.branchName,
          });
        }



     
      }else{
        this.ticketSelected = {}
      }
    },
    async insertIncentiveDetail() {
      this.isLoading = true
      const item = this.incentiveDetailData;
      let obj = {
        ...this.incentiveDetailData,
        ticketNo:item.isBackHome ? this.refTicketNoInput : item.ticketNo ,
        locationForm: item.locationForm.value || item.locationForm,
        locationTo: item.locationTo.value || item.locationTo,
        fkId: this.$route.params.incentiveId,
        createAt: GET_CURRENT_DATE().timestamp,
        ditstanceResult: item.distanceTo - item.distanceFrom,
      };

      //BACK HOME 
      if(this.refTicketNoInput!==""){
        obj = {
          ...obj,
          branchCode : this.ticketRef.branchCode || "xxx",
          ticketNo: this.refTicketNoInput || "xxx",
          isBackHome:1
        }
      }

      const result = await IncentiveOTService.InsertIncentiveDetail(obj);
      this.isLoading = false
      if (result.status == "success") {
        if (this.isback) {
          //BACK HOME
          await this.insertIncentiveDetailForBackHome();          
          await this.getIncentiveDetail();
          this.isback = false;
          this.refTicketNoInput = ""
          this.clearForm()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรายการเรียบร้อย`,
            },
          });          
          await this.getIncentiveDetail();
          this.clearForm()
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }
    },

    async insertIncentiveDetailForBackHome() {
      const item = this.incentiveDetailData.backHome;
      const obj = {
        ...item,
        locationForm: item.locationForm.value,
        locationTo: item.locationTo.value,
        fkId: this.$route.params.incentiveId,
        createAt: GET_CURRENT_DATE().timestamp,
        ditstanceResult: item.distanceTo - item.distanceFrom,
        date: this.incentiveDetailData.date,
        branchCode: this.incentiveDetailData.branchCode || "xxx",
        ticketNo: this.incentiveDetailData.ticketNo || "xxx",
        isBackHome:1
      };

      

      const result = await IncentiveOTService.InsertIncentiveDetail(obj);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }
    },

    async editIncentiveDetail(){
      this.isLoading = true
      const item = this.incentiveDetailData
      let obj = {
        ...this.incentiveDetailData,
        ticketNo:item.isBackHome ? this.refTicketNoInput : item.ticketNo ,
        locationForm: item.locationForm.value || item.locationForm,
        locationTo: item.locationTo.value ||item.locationTo,
        ditstanceResult: item.distanceTo - item.distanceFrom,
      }      
      const result = await IncentiveOTService.UpdateIncentiveDetail(obj, obj.id)
      this.isLoading = false
      if (result.status == "success") {   
    
          if(this.isback==1){     
            await this.insertIncentiveDetailForBackHome()    
            this.isback = false;
            this.refTicketNoInput = ""
            this.clearForm()    
          }

          await this.getIncentiveDetail();
          this.clearForm()  
          this.goToTarget(obj.id,-150)      
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
    },

    onSave(){
      const invalid = this.validateForm()
      if(invalid) return;

      if(this.mode == "create"){
        this.insertIncentiveDetail()
      }else{
        this.editIncentiveDetail()
      }
    },

    onEditIncentiveDetail(item){
      this.mode = "edit"
      
      this.incentiveDetailData = {...IncentiveDetailModel,...item}     
      this.ticketSelected = item;

      //แสดงเลขใบงานอ้างอิงถ้าเป็นขากลับ
      if(item.isBackHome==1){
        this.isback = false
        this.refTicketNoInput = item.ticketNo
      }
      

      //เพิ่มชื่อ location ใน Dropdown
      const foundedLocation = this.locationOptions.some(
        (s) => s.value == item.branchName
      );
      if (!foundedLocation) {
        this.locationOptions.push({
          title: item.branchName,
          value: item.branchName,
        });
      }

      this.goToTarget()
     
    },

    async deleteIncentiveDetail(incentiveDetailId, ticketNo) {
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?");
      if (!isConfirm) {
        return false;
      }

      const result = await IncentiveOTService.DeleteIncentiveDetail(
        incentiveDetailId,
        ticketNo
      );
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "CheckIcon",
            variant: "success",
            text: `ลบรายการเรียบร้อย`,
          },
        });
        this.clearForm()
        this.getIncentiveDetail();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Accounting`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการลบรายการ`,
          },
        });
      }
    },

    onSelectedTicket(item) {
      this.ticketSelected = item;
      this.incentiveDetailData.ticketDescription = item.problemSolving;
      this.incentiveDetailData.ticketNo = item.ticketNo;
      this.incentiveDetailData.branchCode = item.branchCode;

      if(item.isBack){
        this.refTicketNoInput = item.ticketNo
      }
      const foundedLocation = this.locationOptions.some(
        (s) => s.value == item.branchName
      );
      if (!foundedLocation) {
        this.locationOptions.splice(3,0,{
          title: item.branchName,
          value: item.branchName,
        });

        
      }
    },

    computeSummaryAmountFooter(lists){        
      const sumKilo = lists.reduce((acc, value) => acc + value.ditstanceResult, 0);
      const sumCost = lists.reduce((acc, value)=>{
        acc += value.expressPosition + value.allowance + value.incentive + value.feeHotel + value.feeOilGas + value.freeOther     
        return acc
      },0)

      this.footerSummaryAmount ={ sumKilo, sumCost }
    },

    clearForm() {
      this.mode = "create"
      let incentiveDetailObj = {...IncentiveDetailModel}

      this.incentiveDetailData = {...incentiveDetailObj, backHome:incentiveDetailObj.backHome};
      
      this.refTicketNoInput = ""
      this.ticketList = [];
      this.ticketSelected = {};
      this.locationSelected = { title: "", value: "" };
      this.isback = false

    },

    autoRefTicketNoInput(){
        const locationFrom = this.incentiveDetailData.locationForm
        
        if(locationFrom.isBranch){
          this.refTicketNoInput = locationFrom.ticketNo
        }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToTarget(id="targetElement", yOffset=-150) {
      const targetElement = document.getElementById(id);
      if (targetElement) {
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
       
      
      }
    }
  },

  mounted() {
    this.incentiveDetailData = {...IncentiveDetailModel}
    this.getIncentiveDetail();
    this.totalRows = this.incentiveDetailList.length;
  },
};
</script>
