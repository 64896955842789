export default [
  {
    key: "date",
    label: "วันที่",
    sortable: true,
    thStyle:{color:"white", width: "6% !important", textAlign: "center"}
  },
  {
    key: "ticketNo",
    label: "Job No",
    sortable: true,
    thStyle:{color:"white", textAlign: "center"}
  },
  { key: "branchCode", label: "รหัสสาขา", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "locationForm", label: "จาก", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "locationTo", label: "ถึง", sortable: true , thStyle:{color:"white", width: "10% !important", textAlign: "center"}},
  { key: "distanceFrom", label: "ไมล์เริ่มต้น", sortable: false , thStyle:{color:"white", textAlign: "center"}},
  {
    key: "distanceTo",
    label: "ไมล์สิ้นสุด",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white", textAlign: "center"},
  },
  { key: "ditstanceResult", label: "ระยะทาง", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "expressPosition", label: "ค่าทางด่วน", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "allowance", label: "ค่าเบี้ยเลี้ยง", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "incentive", label: "Incentive", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "feeHotel", label: "ค่าโรงแรม", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "feeOilGas", label: "ค่าน้ำมัน/แก๊ส", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  { key: "freeOther", label: "อื่นๆ", sortable: false ,thStyle:{color:"white", textAlign: "center"}},
  {
    key: "ticketDescription",
    label: "รายละเอียด",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white", textAlign: "center" },
  },
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { textAlign: "center", color:"white", width: "8% !important",  textAlign: "center"},
  },
];
