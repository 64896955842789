<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>ระบบเบิกค่าใช้จ่าย</b></h3>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <div v-if="accountUserPermission=='create' || accountUserPermission=='edit'">
          <b-button
            variant="outline-primary"
            size="sm"
            style="height: 35px"
            class="mr-1"
           
            @click="print"
          >
            <feather-icon icon="PrinterIcon" />
            Print
          </b-button>

        <b-button
          variant="dark"
          size="sm"
          class="mr-1"
          style="height: 35px"
          @click="$router.push(`/accounting/clearbill/${$route.params.incentiveId}`)"
          v-if="mode == 'edit'"
        >
          <feather-icon icon="FileTextIcon" />
          Clear bill
        </b-button>
        
        <b-button
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="onSave"
          :disabled="incentiveOtData.processNo!==0"
        >
          <feather-icon icon="SaveIcon" />
          บันทึกเอกสาร
        </b-button>
        </div>
        
        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push('/accounting')"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
        
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col>
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span
              ><strong>สถานะใบเบิก : </strong> 
              {{ statusIncentive}}</span
            >
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      
      <b-col cols="12">       
        <IncentiveOt :incentiveOtData="incentiveOtData" />
        <IncentiveDetail
          v-if="mode == 'edit'"
          :incentiveOtData="incentiveOtData"         
          :userLogin="userLogin" 
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton,  BAlert, } from "bootstrap-vue";

//COMPONENTS
import IncentiveOt from "./component/incentiveOt";
import IncentiveDetail from "./component/incentiveDetail";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICE&UTILL
import IncentiveOTService from "@/services/incentiveOT";
import { GET_CURRENT_DATE } from "@/utils/";
import PrintDoc from "../print/"

//MODEL
import IncentiveOtModel from "./model/"

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert, 
    IncentiveOt,
    IncentiveDetail,
  },
  data() {
    return {
      incentiveOtData: IncentiveOtModel,
      userLogin: {},
      mode: "create",
    };
  },
  async created() { 
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
    
    //WHEN EDIT MODE
    const paramIncentiveId = this.$route.params.incentiveId;
    if (paramIncentiveId) {
      this.mode = "edit";      
      this.onLoadIncentiveOtById(paramIncentiveId)
    } 
  },
  computed :{
    accountUserPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("AC001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    statusIncentive(){
      if(!this.incentiveOtData.processName){
        if(this.mode == "create"){
          return "สร้างใบเบิก"
        }
        return "แก้ไขใบเบิก"
      }
      return this.incentiveOtData.processName
    }
   
  },

  methods: {
    validateForm(){
      if(!this.incentiveOtData.otherDescription){
        alert("กรุณากรอกรายละเอียดใบเบิก")
        return true
      }
      if(!this.incentiveOtData.otherRealGoDate){
        alert("กรุณาเลือกวันที่เดินทางไป")
        return true
      }
      if(!this.incentiveOtData.otherRealBackDate){
        alert("กรุณาเลือกวันที่เดินทางกลับ")
        return true
      }
      if(!this.incentiveOtData.otherTotalDate){
        alert("กรุณาเลือกจำนวนวันที่เดินทาง")
        return true
      }
    },
    async onLoadIncentiveOtById(incentiveOtId){
      try {
        const result = await IncentiveOTService.GetIncentiveOTById(incentiveOtId)
        this.incentiveOtData = result.data    
        this.incentiveOtData.otherTotal = this.computeIncentiveTotal(result.data)
        this.incentiveOtData.otherTotalDate = {title:this.incentiveOtData.otherTotalDate, value:this.incentiveOtData.otherTotalDate}
        this.incentiveOtData.otherRentalDay = {title:this.incentiveOtData.otherRentalDay, value:this.incentiveOtData.otherRentalDay}
        this.incentiveOtData.otherExpensesPerDay = {title:this.incentiveOtData.otherExpensesPerDay, value:this.incentiveOtData.otherExpensesPerDay}

      } catch (error) {
        alert("Load Incentive Ot Data Error")
        console.log(error)
      }
    },

    async onSave(){
      const invalid = this.validateForm()
      if(invalid) return;

      if(this.mode == "create"){
        this.insertIncentiveOt()
      }else{
        this.updateIncentiveOt()
      }
    }, 

    async insertIncentiveOt() {
      try {
        const obj = {
          ...this.incentiveOtData,
          userId: this.userLogin.userId,
          dateSend: GET_CURRENT_DATE().timestamp,
          otherDate: GET_CURRENT_DATE().timestamp,
          otherRentalDay: this.incentiveOtData.otherRentalDay.value,
          otherTotalDate: this.incentiveOtData.otherTotalDate.value,
        };

        console.log(obj);
        const result = await IncentiveOTService.InsertIncentiveOT(obj);

        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "CheckIcon",
              variant: "success",
              text: `สร้างใบเบิกค่าเดินทางเรียบร้อย`,
            },
          });
          this.$router.push("/accounting");
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการสร้างใบเบิกค่าเดินทาง`,
            },
          });
        }
        
      } catch (error) {
        alert("Create Incentive Ot Error")
        console.log(error)
      }
    },

    async updateIncentiveOt(){
      try {
        const obj = {
          ...this.incentiveOtData,                           
          otherRentalDay: this.incentiveOtData.otherRentalDay?.value || 0,
          otherTotalDate: this.incentiveOtData.otherTotalDate?.value || 0,
          otherExpensesPerDay: this.incentiveOtData.otherExpensesPerDay?.value || 0,
        };

        console.log(obj);
        const result = await IncentiveOTService.UpdateIncentiveOT(obj, this.incentiveOtData.id);

        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "CheckIcon",
              variant: "success",
              text: `บันทึกข้อมูลใบเบิกค่าเดินทางเรียบร้อย`,
            },
          });
          this.$router.push("/accounting");
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Accounting`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการบันทึกข้อมูลใบเบิกค่าเดินทาง`,
            },
          });
        }
        
      } catch (error) {
        alert("Create Incentive Ot Error")
        console.log(error)
      }
    },

    print(){
      PrintDoc(this.incentiveOtData)
    },

    computeIncentiveTotal(data){
      const {otherRentalAmount, otherGasOil, otherPayTotal, gasperkilo, otherExpensesPerDayAmount, incentiveTotal ,expressWay} = data
      return otherRentalAmount + otherGasOil + otherPayTotal+otherExpensesPerDayAmount+ incentiveTotal + expressWay
    }
  },

  mounted() {


    //ON LOAD (CREATE MODE)
    if (this.mode == "create") {
      this.incentiveOtData.otherDate = GET_CURRENT_DATE().date; 
      this.incentiveOtData.fullname = `${this.userData.firstname} ${this.userData.lastname}`;
    }


  },
};
</script>
