import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const TicketService = () => {}
//INPROGRESS
TicketService.GetTicketList = async(payload)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/inprogress`, payload)
 }

 TicketService.GetTicketById = async(ticketId, payload)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/inprogress/${ticketId}`, payload)
 }

 TicketService.InsertTicket = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/ticket/inprogress`, payload)
 } 

 TicketService.EditTicket = async(ticketId, payload)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/ticket/inprogress/${ticketId}`, payload)
 } 
 
 

 //CLOSED
TicketService.GetTicketClosedList = async(payload, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/closed?${queryString}`, payload)
}

TicketService.SearchClosedTicket = async(queryString="")=> {  
  const encoded = encodeURIComponent(queryString)  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/closed/search/${encoded}`, {})
}

TicketService.GetTicketClosedSummary = async(queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/closed/summary?${queryString}`, {})
}

 
TicketService.GetTicketByAssigneeId = async(userId, date, queryString=0)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/ticket/closed/assignTo/${userId}?withdrawStatus=${queryString}`, {date})
 }

 TicketService.GetTicketByTicketNo = async(ticketNo)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/closed/ticket/${ticketNo}`, {})
 } 


 //CANCEL
 TicketService.GetTicketCancelList = async(payload, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/cancel?${queryString}`, payload)
}
 export default TicketService