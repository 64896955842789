<template>
  <div>
    <!-- START SECTION FORM วันเดินทาง--->
    <b-card>
      <!--- START (วันที่สร้าง) --->
      <b-row>
        <b-col md="3" class="">
          <label>วันที่</label>
          <b-form-input
            placeholder="2023-02-17"
            size="sm"
            readonly
            value="2023-02-17"
            v-model="incentiveOtData.otherDate"
          />
        </b-col>
        <b-col></b-col>
        <b-col md="2" class="">
          <label>เลขที่</label>
          <h3>
            <b>{{ incentiveOtData.otherNo || "-" }}</b>
          </h3>
        </b-col>
      </b-row>
      <!--- END (วันที่สร้าง) --->

      <!--- START ROW ชื่อผู้เบิก --->
      <b-row class="mt-1">
        <b-col md="3" class="">
          <label>ชื่อผู้เบิก</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="ชื่อผู้เบิก"
            v-model="incentiveOtData.fullname"
            readonly
          />
        </b-col>
      </b-row>
      <!--- END ชื่อผู้เบิก --->

      <!--- START ROW รายละเอียด --->
      <b-row class="mt-1">
        <b-col md="12">
          <label>รายละเอียด <span class="text-danger">*</span></label>
          <b-form-textarea
            id="textarea-default"
            placeholder="รายละเอียด"
            rows="2"
            size="sm"
            v-model="incentiveOtData.otherDescription"
          />
        </b-col>
      </b-row>
      <!--- END รายละเอียด --->

      <!--- START (วันที่เดินทาง) --->
      <b-row class="mt-1">
        <b-col md="3" class="">
          <label>วันเดินทาง <span class="text-danger">*</span></label>
          <b-form-datepicker
            id="example-datepicker"
            locale="th-TH"
            show-decade-nav
            class="mb-1"
            size="sm"
            v-model="incentiveOtData.otherRealGoDate"
          />
        </b-col>
        <b-col md="3" class="">
          <label>วันที่กลับ <span class="text-danger">*</span></label>
          <b-form-datepicker
            id="example-datepicker2"
            locale="th-TH"
            v-model="incentiveOtData.otherRealBackDate"
            show-decade-nav
            class="mb-1"
            size="sm"
          />
        </b-col>
      </b-row>
      <!--- END (วันที่เดินทาง) --->

      <!-- START ROW รวมวันเดินทาง -->
      <b-row class="mt-1">
        <b-col md="3" class="">
          <label>รวมวันเดินทาง <span class="text-danger">*</span></label>
          <v-select
            id="travelDay"
            v-model="incentiveOtData.otherTotalDate"
            label="title"
            :options="dayOption"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <!-- END ROW รวมวันเดินทาง -->
    </b-card>
    <!-- END SECTION FORM วันเดินทาง--->

   
    <!-- START SECTION FORM เบี้ยเลี้ยง--->
    <b-card>
      <!-- START ROW ค่าเบี้ยเลี้ยงต่างจังหวัด (กลางคืน)-->
      <b-row class="py-1 row-hover">
        <b-col md="2" class="">
          <label>ค่าที่พัก</label>         
          <v-select
            id="problemType"
            v-model="incentiveOtData.otherRentalDay"
            label="title"
            :options="dayOption"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
       
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            value="0.00"
            @keyup="onCalculate()"
            v-model.number="incentiveOtData.otherRentalAmount"
          />
        </b-col>
      </b-row>
      <!-- END ROW ค่าเบี้ยเลี้ยงต่างจังหวัด (กลางคืน)-->

      <!-- START ROW ค่าเบี้ยเลี้ยงต่างจังหวัด (กลางวัน) -->
      <b-row class="py-1 row-hover">
        <b-col md="2" class="">
          <label>ค่าเบี้ยเลี้ยงต่างจังหวัด (กลางวัน)</label>
          <v-select
            id="problemType"
            v-model="incentiveOtData.otherExpensesPerDay"
            label="title"
            :options="dayOption"
            class="select-size-sm"
            :clearable="false"
          />
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            
            @keyup="onCalculate()"
            v-model.number="incentiveOtData.otherExpensesPerDayAmount"
            
          />
        </b-col>
      </b-row>
      <!-- END ROW ค่าเบี้ยเลี้ยงต่างจังหวัด (กลางวัน) -->

      <!-- START ROW ค่าน้ำมัน/แก๊ส -->
      <b-row class="py-1 row-hover">
        <b-col md="3" class="">
          <label>ค่าน้ำมัน/แก๊ส</label>
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            value="0.00"
            @keyup="onCalculate()"
            v-model.number="incentiveOtData.otherGasOil"
          />
        </b-col>
      </b-row>
      <!-- END ROW ค่าน้ำมัน/แก๊ส -->

      <!-- START ROW ค่าทางด่วน -->
      <b-row class="py-1 row-hover">
        <b-col md="8" class="">
          <label>ค่าทางด่วน</label>
          
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            value="0.00"
            @keyup="onCalculate()"
            v-model.number="incentiveOtData.expressWay"
          />
        </b-col>
      </b-row>
      <!-- END ROW ค่าทางด่วน -->

      <!-- START ROW ค่าใช้จ่าย อื่นๆ -->
      <b-row class="py-1 row-hover">
        <b-col md="3" class="">
          <label>ค่าใช้จ่าย อื่นๆ</label>
          <b-form-input
            placeholder=""
            size="sm"
            v-model="incentiveOtData.otherPay"
          />
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            v-model.number="incentiveOtData.otherPayTotal"
            value="0.00"
            
          />
        </b-col>
      </b-row>
      <!-- END ROW ค่าใช้จ่าย อื่นๆ -->

      <!-- START ROW Incentive -->
      <b-row class="py-1 row-hover">
        <b-col md="3" class="">
          <label>Incentive</label>
        </b-col>
        <b-col></b-col>

        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            value="0.00"
            v-model.number="incentiveOtData.incentiveTotal"
            @keyup="onCalculate()"
          />
        </b-col>
      </b-row>
      <!-- END ROW Incentive -->

      <!-- START ROW ยอดรวมทั้งหมด -->
      <b-row class="py-1 row-hover d-flex align-items-end">
        <b-col></b-col>
        <b-col md="2" class="d-flex justify-content-end">
          <b>ยอดรวมทั้งหมด</b>
        </b-col>
        <b-col md="2">
          <label>ยอดเงิน</label>
          <b-form-input
            id="smallInput"
            size="sm"
            placeholder="0.00"
            value="0.00"
            disabled
            v-model.number="incentiveOtData.otherTotal"         
          />
        </b-col>
      </b-row>     
      <!-- END ROW ยอดรวมทั้งหมด -->   
    </b-card>
    <!-- END SECTION FORM เบี้ยเลี้ยง--->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
    props:['incentiveOtData'],
    components: {
    BRow,
    BCol,
    BCard,  
    BButton,
    BFormInput,
    BInputGroup,    
    BFormTextarea,
    BFormDatepicker,
    vSelect, 
  },
  data() {
    return {
        dayOption:[],        
    }
  },

  methods:{
    genDayOption(loop = 30){
        let days = []
        for(let i = 0; i <=loop;i++){
            days.push({title:i, value:i})
        }

        this.dayOption = days
    },
    onCalculate(){
      console.log("calculating...")
      this.incentiveOtData.otherTotal = this.computeIncentiveTotal(this.incentiveOtData)
    },

    computeIncentiveTotal(data){
      const {otherRentalAmount, otherGasOil, otherPayTotal, gasperkilo, otherExpensesPerDayAmount, incentiveTotal ,expressWay} = data
      return otherRentalAmount + otherGasOil + otherPayTotal+otherExpensesPerDayAmount+ incentiveTotal + expressWay
    }
  },
  mounted(){
    this.genDayOption()
  }
};
</script>

<style></style>
