export default {
  fkId: 0,
  date: "",
  branchCode: "",
  locationForm: "",
  locationTo: "",
  distanceFrom: 0,
  distanceTo: 0,
  ditstanceResult: 0,
  expressPosition: 0,
  ticketDescription: "",
  ticketNo: "",
  allowance: 0,
  incentive: 0,
  feeHotel: 0,
  feeOilGas: 0,
  freeOther: 0,
  orderNo: 1,
  backHome: {
    fkId: 0,
    date: "",
    branchCode: "",
    locationForm: "",
    locationTo: "",
    distanceFrom: 0,
    distanceTo: 0,
    ditstanceResult: 0,
    expressPosition: 0,
    ticketNo: "",
    allowance: 0,
    incentive: 0,
    feeHotel: 0,
    feeOilGas: 0,
    freeOther: 0,
    orderNo: 1,
    ticketDescription: "ขากลับ",
  },
};
