export default {
    otherDate: null,
    userId: null,
    otherDescription: "",
    otherRealGoDate: "",
    otherRealBackDate: "",
    otherTotalDate: 1,
    otherRentalDay: 1,
    otherRentalAmount: 0,
    otherExpensesPerDay: 1,
    otherExpensesPerDayAmount: 0,
    otherGasOil: 0,
    otherPay: "",
    otherPayTotal: 0,
    otherTotal: 0,
    incentiveTotal: 0,
    gasperkilo: 0,
    expressWay:0,
    statusCheck: null,
    dateSend: null,
    processNo: 0,
    fullname: "", 
  }